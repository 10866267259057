.app-element-page {
  margin-top: 70px;

  .panel {
    margin-bottom: 90px;

    .btn-link {
      text-align: left;
    }

    .form-label {
      font-size: 16px;
      font-weight: bold;
      margin-top: 1rem;
    }

    .form-check {
      margin-top: 1rem;
    }
  }

  .button-control {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;

    > div {
      text-align: right;
      button {
        margin: 1rem;
      }
    }
  }
}
