.icon-btn {
  height: 35px;
}

.title {
  font-size: 2.5em;
  font-weight: bold;
  color: #0072bb;
}

a.nostyle:link {
  text-decoration: inherit;
  color: #000;
  /* cursor: auto; */
}

a.nostyle:visited {
  text-decoration: inherit;
  color: #000;
  /* cursor: auto; */
}

.cards-container {
  height: 35vh;
  overflow: hidden;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-column-gap: 10px;
  /* grid-row-gap: 0px; */
}

.card-item {
  border-radius: 15px;
  width: 400px;
}

.card-item:hover {
  box-shadow: 0px 0px 15px 1px #0572b562;
}

.card-item .card-title {
  text-align: center;
  background: #0072bb;
  border-radius: 15px 15px 0px 0px;
  min-height: 7vh;
  border: 1px solid #0072bb;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-body-home{
  padding:0.7rem !important;
}

.footer-home {
  /* background: #1d9ce5; */
  background: #e1e1e1;
  bottom: 0px;
  position: fixed;
  width: 100vw;
  text-align: right;
}

@media only screen and (max-width: 1024px) {
  .card-item {
    border-radius: 15px;
    width: 300px;
  }
  .cards-container {
    height: 40vh;
    overflow: hidden;
    display: grid;
    grid-template-columns: auto auto auto;
    grid-column-gap: 10px;
    /* grid-row-gap: 0px; */
  }
  
}

@media only screen and (max-width: 1700px) {

  .cards-container {
    height: 45vh;
    overflow: hidden;
    display: grid;
    grid-template-columns: auto auto auto;
    grid-column-gap: 10px;
    /* grid-row-gap: 0px; */
  }
  
}