.app-list-page {
  padding-top: 0.4rem;
  margin-top: 70px;
  margin-bottom: 70px;

  .table {
    max-height: 70vh;
    overflow-y: visible;
    margin-bottom: 70px;
  }

  .control {
    padding-top: 1rem;
    padding-bottom: 1rem;
    text-align: right;

    .search-content {
      display: inline-flex;
    }
  }
}
